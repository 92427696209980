import { FC, ComponentProps } from "react";

import { cn } from "~/lib/utils";

export const H1: FC<ComponentProps<"h1">> = ({ children, className, ...props }) => {
  return (
    <h1 className={cn("scroll-m-20 text-3xl font-bold tracking-tight", className)} {...props}>
      {children}
    </h1>
  );
};

export const H2: FC<ComponentProps<"h2">> = ({ children, className, ...props }) => {
  return (
    <h2 className={cn("scroll-m-20 text-2xl font-semibold tracking-tight", className)} {...props}>
      {children}
    </h2>
  );
};

export const H3: FC<ComponentProps<"h3">> = ({ children, className, ...props }) => {
  return (
    <h3 className={cn("scroll-m-20 text-xl font-semibold tracking-tight", className)} {...props}>
      {children}
    </h3>
  );
};

export const H4: FC<ComponentProps<"h4">> = ({ children, className, ...props }) => {
  return (
    <h4 className={cn("scroll-m-20 text-lg font-semibold tracking-tight", className)} {...props}>
      {children}
    </h4>
  );
};

export const P: FC<ComponentProps<"p">> = ({ children, className, ...props }) => {
  return (
    <p className={cn("leading-7", className)} {...props}>
      {children}
    </p>
  );
};
